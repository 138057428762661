.container-wd {
  color: #c6c0c0;
    /* margin: auto; */
    justify-content: center;
}
#windDirectionCanvas {
    display:block; /* inline adds an extra 4px to the height of the element that doesn't show up on the layout diagram */
    width: 100%;
    height: 100%;
}
.title {
    /* margin: auto; */
    color: #a39d9d;
    text-align: center;
    margin-top: 10px;
}