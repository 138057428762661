.settingsItem {
    display: flex;
    flex-direction: column;
    margin-left: 45px;
    margin-right: 45px;
}
.saveSettingsButton {
   width: 33%;
}
.actionButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    /* width: 300px; */
}
.settingsSlider {
    margin-top: 30px;
}
.additionalObjectsList {
    max-height: 225px;
    overflow-y: scroll;
}
.additionalObjectItem {
    font-size: .9em;
}