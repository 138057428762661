
@media screen and (max-width: 600px) {
    .expandedNightReport {
        display: grid;
        grid-template-rows: 50% 50%;
        grid-row: 2 / 8;
        overflow-y: scroll;
    }
    .dataGraph {
        /* height: 500px; */
        /* height: 50%; */
    }
    #weatherData {
        /* height: 500px; */
        /* height: 100% !important; */
        grid-row: 1;
    }
    #positionData {
        /* height: 500px; */
        /* height: 100% !important */
       grid-row: 2;


    }
    #starmap {
        grid-row: 3;
        height: 500px;
    }
}
@media screen and (min-width: 600px) {
    
    .expandedNightReport {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 50% 50%;
        grid-column: 2 / 8;
    }
}