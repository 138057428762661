
@media screen and (min-width: 600px) {
  .nightReport {
    border-radius: 15px;
    padding: 15px;
    /* height: 100%; */
    margin: 5px;
  }
  .weekCalendarDisplay {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    height: calc(100vh - 38px);
    background-color: black;
  }
  
  
}
.hoverThemed {
  background-color: rgb(33, 32, 32);
  color: #c6c0c0 !important;

}
.themed {
  background-color: black !important;
  color: #c6c0c0 !important;

}
.configurator {
  background-color: black;
  color: #c6c0c0;
}
.centeredElement {
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
.errorMessage {
  color: #a83434;


}

.locationSelector {
  /* width: calc(100vw - 90px); */
  flex-grow: 1;

}
.configurator {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  /* flex-flow: ; */
  /* align-items: center; */
}
.locationNameDisplay {
  margin-left: 25px;
  
}
@media screen and (max-width: 600px) {
  .nightReport {
    border-radius: 15px;
    /* padding: 15px; */
    /* height: 100%; */
    margin: 5px;
  }
  .weekCalendarDisplay {
    display: grid;
    grid-template-rows: repeat(7, 1fr);
    height: calc( 100vh - 44px);
    background-color: black;
  }
}