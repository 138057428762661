@media screen and (min-width: 600px) {

    .nightReport {
        background-color: #14213D;
        color: #c6c0c0;
        display: flex;
        flex-direction: column;
        align-items: center;
        animation: fadeIn 1s;
        max-height: 100%;
        overflow-y: scroll;
        position: relative;
    }
    @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
    .visiblePlanetEntry {
        display: grid;
        grid-template-columns: 100px 100px;
        font-size: .9em;
    }
    .visiblePlanetIcon {
        /* grid-column: 1; */
        max-width: 96px;
    }
    .visiblePlanetInfoIcon {
        width: 1em;
        height: 1em;
    }
    
    .visiblePlanetNameTag {
        margin-bottom: 0px;
        margin-top: 0px;
    }
    .navButtons {
        position:absolute;
        top: 45px;
        width: 75%;
        display: flex;
        justify-content: space-between;
    }
    .navButton:hover {
        text-decoration: underline;
        font-weight: bold;
    }
    .mobileMoonPhaseDisplay { 
        display: none;
    }
}
.moonPhaseIcon {
    max-height: 1em;
    margin-left: 5px;
    margin-right: 5px;
}
.superMoonTag {
    color: #d9c855;
}

@media screen and (max-width: 600px) {
    .nightReport {
        background-color: #14213D;
        color: #c6c0c0;
        display: grid;
        grid-template-columns: 25% 37% 37%;
        grid-template-rows: 50% 50%;
        /* flex-direction: column; */
        padding: 5px;
        align-items: center;
        animation: fadeIn 1s;
        /* max-height: 100%; */
        overflow-y: scroll;
        position: relative;
    }
    .dateDisplay {
        grid-row: 1;
        grid-column: 1;
        margin: auto;
    }
    .dateName {
        grid-row: 2;
        grid-column: 1;
        margin: auto;
    }
    .cloudCoverDisplay {
        grid-column: 2;
        grid-row: 1;
        margin: auto;
    }
    .sunsetTime {
        grid-column: 3;
        grid-row: 1;
        margin: auto;

    }
    .temperatureDisplay {
        grid-column: 2;
        grid-row: 2;
    }

    .visiblePlanetsDisplay {
        margin-left: calc(100% - 140px);
        max-width: 140px;
        flex-wrap: wrap;
        overflow-x: hidden;
        grid-column: 2 / 4;
        grid-row: 2;
        display: flex;
        flex-direction: row;
        justify-content: right;
        width: 100%;
    }
    .mobileMoonPhaseDisplay { 
        grid-column: 2;
        grid-row: 2;
        display: flex;
        flex-direction: row-reverse;
    }
    .visiblePlanetInfo {
        display: none;
    }
    .visiblePlanetIcon {
        width: 1em;
        height: 1em;
    }
    .visiblePlanetInfoIcon {
        width: 1em;
        height: 1em;
    }

    @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }
    /* .visiblePlanetEntry {
        display: grid;
        grid-template-columns: 100px 100px;
    }
     */
    
    .visiblePlanetIcon {
        /* grid-column: 1; */
    }
    /* .visiblePlanetNameTag {
        margin-bottom: 0px;
        margin-top: 0px;
    } */
    .navButtons {
        position:absolute;
        top: 45px;
        width: 100%;
        display: flex;
        
        justify-content: space-between;
    }
    .navButton:hover {
        text-decoration: underline;
        font-weight: bold;
    }
    
}
.collapseButton {
    position: absolute;
    top: 5px;
    right: 10px;
}